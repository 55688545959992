import { useReactOidc } from '@axa-fr/react-oidc-context';
import { Field, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { getCountries } from '../../../../api/country';
import { getCustomerInfo, updateCustomer } from '../../../../api/customer';
import { tailwindToast } from '../../../../components/Toast/tailwindToast';
import { useToastAction } from '../../../../hooks/useToastAction';
import { ContentWrapper, CoreButton, PageHeader } from '@metaforcelabs/metaforce-core';

export default function SettingsAccount() {
  const [countries, setCountries] = useState([]);
  const [customer, setCustomer] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const { oidcUser } = useReactOidc();
  const loadAction = useToastAction();

  const loadDefaultData = async () => {
    setIsLoaded(false);
    loadAction.execute(async () => {
        const serverCountries =  await getCountries();
        setCountries(serverCountries);
        const serverCustomer = await getCustomerInfo();
        setCustomer(serverCustomer);
        setIsLoaded(true);
    }, 'Failed to load customer and/or countries')
}

  useEffect(() => {
    if (isLoaded) {
        return;
    }
    loadDefaultData();
  }, [])

  const handleSubmitForm = async (values) => {
    try {
      await updateCustomer(oidcUser.access_token, values);
      setCustomer(values);
      tailwindToast.success("Customer updated");
    } catch (error) {
      tailwindToast.error("Failed to update customer");
    }
  }


  return (
    !loadAction.isExecuting && isLoaded && (
      <>
        <ContentWrapper>
              <Formik
                initialValues={customer}
                enableReinitialize={true}
                onSubmit={async (values, { setSubmitting }) => {
                  await handleSubmitForm(values);
                  setSubmitting(false);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  resetForm,
                  validateForm,
                  setFieldValue
                  /* and other goodies */
                }) => (
                  <form
                    onSubmit={e => {
                      e.preventDefault();
                      handleSubmit(e)
                    }}
                    onReset={resetForm} >
                      <div className="space-y-8 divide-y divide-gray-200">
                        <div>
                          <PageHeader title="Account Settings" />
                          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-3">
                            <div>
                              <label htmlFor="account-name" className="block text-sm font-medium text-gray-700">
                                Account name
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  disabled={true}
                                  value={values.name}
                                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                              </div>
                            </div>

                            <div>
                              <label htmlFor="organizational-number" className="block text-sm font-medium text-gray-700">
                                Organizational number
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  disabled={true}
                                  value={values.orgNumber}
                                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                              </div>
                            </div>

                            <div>
                              <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                                Country
                              </label>
                              <div className="mt-1">
                                <select
                                  disabled={true}
                                  value={values.country?.code}
                                  className="col-span-6 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" >
                                  <option key="default" value=""></option>
                                  {
                                    countries.map(c => {
                                      return (<option key={c.code} value={c.code}>{c.name}</option>)
                                    })
                                  }
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="pt-10">
                          <div>
                            <h3 className="text-lg leading-6 font-medium text-gray-900">Billing address</h3>
                          </div>
                          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                              <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                                Street address
                              </label>
                              <div className="mt-1">
                                <Field
                                  type="text"
                                  value={values.billingAddress?.streetName}
                                  disabled={isSubmitting}
                                  id="billingAddress.streetName"
                                  autoComplete="street-address"
                                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                              </div>
                            </div>

                            <div className="sm:col-span-3">
                              <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                                State / Province
                              </label>
                              <div className="mt-1">
                                <Field
                                  type="text"
                                  value={values.billingAddress?.region}
                                  disabled={isSubmitting}
                                  id="billingAddress.region"
                                  autoComplete="address-level1"
                                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                              </div>
                            </div>

                            <div className="sm:col-span-2">
                              <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                                City
                              </label>
                              <div className="mt-1">
                                <Field
                                  value={values.billingAddress?.city}
                                  disabled={isSubmitting}
                                  type="text"
                                  id="billingAddress.city"
                                  autoComplete="address-level2"
                                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                              </div>
                            </div>

                            <div className="sm:col-span-1">
                              <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                                Postal code
                              </label>
                              <div className="mt-1">
                                <Field
                                  type="text"
                                  value={values.billingAddress?.postalCode}
                                  disabled={isSubmitting}
                                  id="billingAddress.postalCode"
                                  autoComplete="postal-code"
                                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="pt-10">
                          <div>
                            <h3 className="text-lg leading-6 font-medium text-gray-900">Visiting address</h3>
                          </div>
                          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                              <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                                Street address
                              </label>
                              <div className="mt-1">
                                <Field
                                  type="text"
                                  value={values.visitingAddress?.streetName}
                                  disabled={isSubmitting}
                                  id="visitingAddress.streetName"
                                  autoComplete="street-address"
                                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                              </div>
                            </div>

                            <div className="sm:col-span-3">
                              <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                                State / Province
                              </label>
                              <div className="mt-1">
                                <Field
                                  type="text"
                                  value={values.visitingAddress?.region}
                                  disabled={isSubmitting}
                                  id="visitingAddress.region"
                                  autoComplete="address-level1"
                                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                              </div>
                            </div>

                            <div className="sm:col-span-2">
                              <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                                City
                              </label>
                              <div className="mt-1">
                                <Field
                                  type="text"
                                  value={values.visitingAddress?.city}
                                  disabled={isSubmitting}
                                  id="visitingAddress.city"
                                  autoComplete="address-level2"
                                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                              </div>
                            </div>

                            <div className="sm:col-span-1">
                              <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                                Postal code
                              </label>
                              <div className="mt-1">
                                <Field
                                  type="text"
                                  value={values.visitingAddress?.postalCode}
                                  disabled={isSubmitting}
                                  id="visitingAddress.postalCode"
                                  autoComplete="postal-code"
                                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="pt-10">
                          <div>
                            <h3 className="text-lg leading-6 font-medium text-gray-900">Contact person</h3>
                          </div>
                          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                            <div className="sm:col-span-1">
                              <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                                Title
                              </label>
                              <div className="mt-1">
                                <Field
                                  type="text"
                                  value={values.contactPerson?.title}
                                  disabled={isSubmitting}
                                  id="contactPerson.title"
                                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                              </div>
                            </div>
                            <div className="sm:col-span-2">
                              <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                First name
                              </label>
                              <div className="mt-1">
                                <Field
                                  type="text"
                                  value={values.contactPerson?.firstName}
                                  disabled={isSubmitting}
                                  id="contactPerson.firstName"
                                  autoComplete="given-name"
                                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                              </div>
                            </div>

                            <div className="sm:col-span-3">
                              <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                                Last name
                              </label>
                              <div className="mt-1">
                                <Field
                                  type="text"
                                  value={values.contactPerson?.lastName}
                                  disabled={isSubmitting}
                                  id="contactPerson.lastName"
                                  autoComplete="family-name"
                                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                              </div>
                            </div>


                            <div className="sm:col-span-3">
                              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Email address
                              </label>
                              <div className="mt-1">
                                <Field
                                  value={values.contactPerson?.email}
                                  disabled={isSubmitting}
                                  id="contactPerson.email"
                                  type="email"
                                  autoComplete="email"
                                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                              </div>
                            </div>
                            <div className="sm:col-span-3">
                              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Phone
                              </label>
                              <div className="mt-1">
                                <Field
                                  value={values.contactPerson?.phone}
                                  disabled={isSubmitting}
                                  id="contactPerson.phone"
                                  type="text"
                                  autoComplete="phone"
                                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                              </div>
                            </div>
                          </div>
                        </div>


                      </div>

                      <div className="pt-10 flex flex-row-reverse">
                          <CoreButton label={<>Save</>} htmlType='submit' disabled={isSubmitting} htmlClassNames='w-full sm:w-0' />
                      </div>
                  </form>)}
              </Formik>
              </ContentWrapper>
      </>
    )
  )
}
